<template lang="pug">
  .root
    component-header-login
    div(class="container-flex")
      div(class="content-info")
        <h3 style="text-align:center">クラファン利用規約</h3>
        div(class="term-content")
          <div v-html="text"></div>

    component-footer-index
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>

import { getStaticFundsTerms } from '@/api/system'

export default {
  name: "TermsFund",
  components: {
  },
  data() {
    return {
      text: '',
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    await this.init();
  },
  updated() {},
  destroyed() {},
  methods: {
    async init() {
      const { fund_terms_of_use } = await getStaticFundsTerms();
      this.text = fund_terms_of_use.content.describe;
    },
  }
};
</script>
